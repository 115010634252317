/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@font-face {
  font-family: "SF Compact Rounded";
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Regular.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Regular.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: bold;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Bold.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Bold.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 800;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Heavy.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Heavy.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 900;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Black.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Black.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 500;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Medium.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Medium.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 300;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Light.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Light.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 200;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Ultralight.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Ultralight.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 600;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Semibold.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Semibold.woff") format("woff");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 100;
  src: url("../fonts/SFCompactRounded/SFCompactRounded-Thin.woff2") format("woff2"), url("../fonts/SFCompactRounded/SFCompactRounded-Thin.woff") format("woff");
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button:not(:disabled) {
  cursor: pointer;
}
button:focus {
  outline: none;
}

body {
  display: block;
  position: relative;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.16;
  background: #1c002f;
  min-width: 320px;
  color: #000;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
body.lock {
  overflow-y: hidden;
}
body ::-webkit-scrollbar {
  width: 5px;
}
body.rtl {
  font-family: "Noto Sans Hebrew", "Arial", sans-serif;
}
body ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body ::-webkit-scrollbar-thumb {
  background: #E7BB90;
}
body ::-webkit-scrollbar-thumb:hover {
  background: #9C8F63;
}

main {
  flex-grow: 1;
}

input,
textarea {
  box-shadow: none;
}

::placeholder {
  opacity: 0.7;
}

:-ms-input-placeholder {
  opacity: 0.7;
}

::ms-input-placeholder {
  opacity: 0.7;
}

.custom-typography h1, .custom-typography .h1 {
  font-family: "SF Compact Rounded", sans-serif;
}
.custom-typography h2, .custom-typography .h2 {
  font-family: "SF Compact Rounded", sans-serif;
}
.custom-typography h3, .custom-typography .h3 {
  font-family: "SF Compact Rounded", sans-serif;
}
.custom-typography h4, .custom-typography .h4 {
  font-family: "SF Compact Rounded", sans-serif;
}
.custom-typography h5, .custom-typography .h5 {
  font-family: "SF Compact Rounded", sans-serif;
}
.custom-typography h6, .custom-typography .h6 {
  font-family: "SF Compact Rounded", sans-serif;
}
.custom-typography p {
  font-family: "SF Compact Rounded", sans-serif;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  min-width: 200px;
  color: #fff;
  text-shadow: 0px 2px 4.62px rgba(9, 43, 0, 0.64);
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  background-image: url(../img/Button_bg-min.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: all 0.3s ease;
}
.btn.isDisabled, .btn:disabled, .btn[disabled] {
  cursor: not-allowed;
  background-image: url(../img/Button_bg-disable.png);
}

.white-link {
  display: inline-block;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: relative;
}
.white-link::before {
  content: "";
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 575px) {
  .white-link {
    font-size: 13px;
  }
}

.posr {
  position: relative;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.df {
  display: flex;
}

.ovh {
  overflow: hidden;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m0 {
  margin: 0 !important;
}

.order-first {
  order: -1;
}

@media only screen and (min-width: 576px) {
  .order-first-sm {
    order: -1;
  }
}

@media only screen and (min-width: 768px) {
  .order-first-md {
    order: -1;
  }
}

@media only screen and (min-width: 992px) {
  .order-first-lg {
    order: -1;
  }
}

@media only screen and (min-width: 1366px) {
  .order-first-xl {
    order: -1;
  }
}

@media only screen and (min-width: 1920px) {
  .order-first-xxl {
    order: -1;
  }
}

.mb-1 {
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .mb-1 {
    margin-bottom: 70px;
  }
}

.mb-2 {
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .mb-2 {
    margin-bottom: 80px;
  }
}

.mb-3 {
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) {
  .mb-3 {
    margin-bottom: 100px;
  }
}

.mt-1 {
  margin-top: 50px;
}
@media only screen and (min-width: 768px) {
  .mt-1 {
    margin-top: 70px;
  }
}

.mt-2 {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) {
  .mt-2 {
    margin-top: 80px;
  }
}

.mt-3 {
  margin-top: 60px;
}
@media only screen and (min-width: 768px) {
  .mt-3 {
    margin-top: 100px;
  }
}

.pb-1 {
  padding-bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .pb-1 {
    padding-bottom: 70px;
  }
}

.pb-2 {
  padding-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .pb-2 {
    padding-bottom: 80px;
  }
}

.pb-3 {
  padding-bottom: 60px;
}
@media only screen and (min-width: 768px) {
  .pb-3 {
    padding-bottom: 100px;
  }
}

.pt-1 {
  padding-top: 50px;
}
@media only screen and (min-width: 768px) {
  .pt-1 {
    padding-top: 70px;
  }
}

.pt-2 {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) {
  .pt-2 {
    padding-top: 80px;
  }
}

.pt-3 {
  padding-top: 60px;
}
@media only screen and (min-width: 768px) {
  .pt-3 {
    padding-top: 100px;
  }
}

.p-1 {
  padding: 50px;
}
@media only screen and (min-width: 768px) {
  .p-1 {
    padding: 70px;
  }
}

.p-2 {
  padding: 40px;
}
@media only screen and (min-width: 768px) {
  .p-2 {
    padding: 80px;
  }
}

.p-3 {
  padding: 60px;
}
@media only screen and (min-width: 768px) {
  .p-3 {
    padding: 100px;
  }
}

.preloader {
  display: none;
}

.js .preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1c002f;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-transform: uppercase;
}
.js .preloader img {
  padding-left: 25px;
}

main {
  background-image: url(../img/main_bg_empty-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 0;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  main {
    padding: 0;
  }
}

.main-title {
  max-width: 450px;
  margin: 0 auto 20px;
  font-family: "SF Compact Rounded", sans-serif;
  color: #ffea00;
  font-size: 35px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 5px 20px rgba(0, 0, 0, 0.6);
}
@media (max-width: 1600px) {
  .main-title {
    max-width: 400px;
    font-size: 30px;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 575px) {
  .main-title {
    max-width: 360px;
    font-size: 26px;
  }
}

.main-subtitle {
  max-width: 310px;
  margin: 0 auto 20px;
  font-family: "SF Compact Rounded", sans-serif;
  color: #f99f10;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
}
@media (max-width: 1600px) {
  .main-subtitle {
    max-width: 270px;
    font-size: 25px;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 575px) {
  .main-subtitle {
    max-width: 255px;
    font-size: 23px;
  }
}

.main-box {
  padding: 50px 50px 10px;
  background-image: url(../img/bg-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  min-width: 640px;
  width: 33vw;
  min-height: 45vh;
  border: solid 5px #ffdd7f;
  box-shadow: 0px 1px 7.76px 0.24px rgba(0, 0, 0, 0.91);
  position: relative;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1365px) {
  .main-box {
    max-width: 80vw;
  }
}
@media only screen and (max-width: 767px) {
  .main-box {
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    border: none;
    border-radius: 0;
    padding: 65px 20px 100px;
  }
}
@media only screen and (max-width: 575px) {
  .main-box {
    padding: 55px 20px 100px;
  }
}
.main-box::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/light2.png);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.main-box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 270px;
  width: 100%;
  background-image: linear-gradient(to top, #240427, #240427 80%, transparent);
  display: none;
}
@media only screen and (max-width: 767px) {
  .main-box::before {
    display: block;
  }
}
.main-box__title {
  font-family: "SF Compact Rounded", sans-serif;
  color: #f99f10;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
}
@media only screen and (max-width: 767px) {
  .main-box__title {
    font-size: 18px;
  }
}
.main-box__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.main-box__left {
  position: absolute;
  top: 43%;
  right: calc(50% + 220px);
  width: 400px;
  transform: translate(0, -50%);
}
@media only screen and (max-width: 991px) {
  .main-box__left {
    display: none;
  }
}
.main-box__right {
  position: absolute;
  top: 44%;
  left: calc(50% + 180px);
  width: 400px;
  transform: translate(0, -50%);
}
@media only screen and (max-width: 991px) {
  .main-box__right {
    display: none;
  }
}
.main-box__top {
  position: absolute;
  width: 350px;
  min-width: 300px;
  top: -130px;
  margin: 0 auto;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .main-box__top {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 575px) {
  .main-box__top {
    max-width: 270px;
    min-width: auto;
  }
}
.main-box__logo {
  max-width: 250px;
  position: absolute;
  top: calc(100% + 30px);
  left: 50%;
  transform: translate(-50%, 0);
}
@media (max-width: 1600px) {
  .main-box__logo {
    max-width: 155px;
    top: calc(100% + 10px);
  }
}
@media only screen and (max-width: 767px) {
  .main-box__logo {
    top: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .main-box__logo {
    max-width: 125px;
  }
}
.main-box__bottom {
  z-index: 10;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-box__notes {
  max-width: 450px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .main-box__notes {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-top: 0;
  }
}
@media only screen and (max-width: 575px) {
  .main-box__notes {
    max-width: 200px;
    font-size: 13px;
  }
}
.main-box__notes a {
  color: #f99f10;
}
.main-box__notes-up {
  max-width: 230px;
  margin: 10px auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 575px) {
  .main-box__notes-up {
    max-width: 240px;
    font-size: 13px;
  }
}
.main-box__number {
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: 300px;
  margin: 0 auto;
}
.main-box.account {
  padding-bottom: 5px;
}

.account__top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.account__photo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 2px solid #fff;
  transform: rotate(45deg);
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account__photo img {
  width: 60px;
  height: 60px;
  transform: rotate(-45deg);
  object-fit: cover;
}
.account__details {
  margin-left: 20px;
}
.account__details, .account__text {
  font-family: "SF Compact Rounded", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  text-shadow: 0px 4px 8.4px rgb(0, 0, 0);
}
.account__name {
  margin-bottom: 2px;
  width: 100px;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.account__total {
  margin-left: 10px;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #ffea00;
  text-shadow: 2px 2px 2px #4c1e02;
}
.account__powered {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 11px;
  color: #fff;
}
.account__powered img {
  margin-left: 10px;
  max-width: 60px;
}
.account .main-box__title {
  max-width: 340px;
  margin: 0 auto 20px;
}
@media only screen and (max-width: 767px) {
  .account .main-box__title {
    max-width: 310px;
  }
}

.get-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 20px;
}
.get-amount__title {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 30px;
  text-align: center;
  font-size: 20px;
  height: 100%;
  font-family: "SF Compact Rounded", sans-serif;
  font-weight: bold;
  color: #ffea00;
  text-shadow: 2px 2px 2px #4c1e02;
  text-transform: uppercase;
  position: relative;
  background-image: url(../img/you_get.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.get-amount__count {
  display: block;
  text-align: center;
  height: 100%;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #ffea00;
  text-shadow: 2px 2px 2px #4c1e02;
}
.get-amount__count.changed {
  animation-name: change-amount;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.get-amount__light {
  max-width: 300px;
  position: absolute;
  bottom: -15px;
  object-fit: cover;
}

@keyframes change-amount {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.animation-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}
.animation-background.show {
  opacity: 1;
}
.animation-background svg {
  margin-left: 30px;
  width: 800px !important;
  height: 800px !important;
}

.get-slider {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}
@media only screen and (max-width: 575px) {
  .get-slider {
    max-width: 60%;
  }
}
.get-slider__note {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  color: #fff;
  font-size: 13px;
}
.get-slider .swiper-wrapper {
  max-width: 100vw;
}
.get-slider .swiper-container {
  width: 100%;
  max-width: 500px;
  height: 50px;
  margin: 0 auto;
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, transparent 10%, black 20%, black 80%, transparent 90%, transparent);
}
.get-slider .swiper-slide-container {
  text-align: center;
  height: 100%;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 25px;
  line-height: 50px;
  font-weight: bold;
  color: #ffea00;
  text-shadow: 2px 2px 2px #4c1e02;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.get-slider .swiper-slide-active .swiper-slide-container {
  transform: scale(1.1);
  font-size: 40px;
}
.get-slider .swiper-button-prev,
.get-slider .swiper-button-next {
  position: absolute;
  background-position: center;
  background-size: contain;
  width: 40px;
}
.get-slider .swiper-button-prev:after,
.get-slider .swiper-button-next:after {
  display: none;
}
.get-slider .swiper-button-prev {
  background-image: url(../img/arrow_left-min.png) !important;
  left: 0px;
}
.get-slider .swiper-button-next {
  background-image: url(../img/arrow_right-min.png) !important;
  right: 0px;
}

#phone {
  padding: 8px 13px 8px 60px;
  color: #fff;
  background-color: #250d26;
  border: 2px solid #ffdd7f;
  border-radius: 10px;
  width: 300px;
  color: #ffdd7f;
}

.phone-label {
  margin-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 0px 1px 6.029px rgba(56, 0, 0, 0.85);
}

.iti__country-list {
  min-width: 300px;
  border: 2px solid #ffdd7f;
  background-color: #250d26;
  border-radius: 0 0 10px 10px;
  z-index: 0;
  margin-top: -7px;
  font-size: 15px;
  font-weight: bold;
  text-shadow: 0px 1px 6.029px rgba(56, 0, 0, 0.85);
}
@media only screen and (max-width: 767px) {
  .iti__country-list {
    border-radius: 10px;
  }
}

.iti__country-list--dropup {
  border-radius: 10px 10px 0 0;
  margin-top: 0;
  margin-bottom: -7px;
}
@media only screen and (max-width: 767px) {
  .iti__country-list--dropup {
    border-radius: 10px;
  }
}

.iti__country-name,
.iti__dial-code {
  color: #fff;
  padding-left: 10px;
}

.iti__arrow {
  border-top: 4px solid #ffdd7f;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #ffdd7f;
}

.iti__arrow {
  order: -1;
  margin-right: 10px;
}

#phone-template {
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 35px;
}
@media only screen and (max-width: 767px) {
  #phone-template {
    padding-bottom: 0;
  }
}

#varification-template {
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 35px;
}

.timer .timer-on,
.timer .timer-off {
  display: none;
}
.timer .timer-on.active,
.timer .timer-off.active {
  display: block;
}

.number-code {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  position: relative;
}
.number-code > input:not(:last-child) {
  margin-right: 12px;
}
.number-code .hint {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  color: #ff0d0d;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.number-code .hint.show {
  display: block;
}

input.code-input {
  font-size: 30px;
  line-height: 1;
  width: 50px;
  text-align: center;
  flex: 0 0 0;
  padding: 5px;
  border-radius: 15px;
  color: #ffdd7f;
  background: #250d26;
  border: 2px solid #ffdd7f;
}
@media only screen and (max-width: 575px) {
  input.code-input {
    width: 38px;
    font-size: 20px;
    border-radius: 10px;
  }
}
input.code-input:invalid {
  box-shadow: none;
}
input.code-input:focus {
  outline: none;
}

.number-code.error input.code-input {
  color: #ff0d0d;
  border: 2px solid #ff0d0d;
}

.extra {
  width: 90px;
  height: 150px;
  position: absolute;
  right: -40px;
  top: -40px;
  background-image: url(../img/vip_badge-min.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 767px) {
  .extra {
    display: none;
    width: 70px;
    height: 100px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.extra__text {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #4a0f04;
  text-shadow: 0px 0.5px 0px rgba(74, 15, 4, 0.78);
}
@media only screen and (max-width: 767px) {
  .extra__text {
    width: 70px;
    height: 70px;
    font-size: 15px;
  }
}
.extra.mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .extra.mobile {
    display: block;
  }
}

.notification-modal {
  min-height: 360px;
  position: relative;
  background: #250d26;
  padding: 40px 25px 30px;
  width: auto;
  max-width: 360px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: solid 5px #ffdd7f;
  border-radius: 20px;
}
.notification-modal__title {
  font-family: "SF Compact Rounded", sans-serif;
  color: #f99f10;
  font-size: 30px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
}
.notification-modal__text {
  font-family: "SF Compact Rounded", sans-serif;
  color: #f99f10;
  font-size: 30px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
}
@media (max-width: 1600px) {
  .notification-modal__text {
    font-size: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .notification-modal__text {
    font-size: 23px;
  }
}
.notification-modal__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification-modal .mfp-close {
  display: none;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.balance {
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 8.4px rgb(0, 0, 0);
}
@media only screen and (max-width: 575px) {
  .balance {
    max-width: 200px;
    font-size: 13px;
  }
}
.balance__amount {
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  color: #ffea00;
  text-shadow: 2px 2px 2px #4c1e02;
}

a.resend-link,
button.resend-link {
  padding-right: 5px;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Compact Rounded", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: relative;
}
a.resend-link::before,
button.resend-link::before {
  content: "";
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #fff;
}
a.resend-link::after,
button.resend-link::after {
  content: "";
  position: absolute;
  right: -15px;
  bottom: -5px;
  margin-left: 3px;
  width: 18px;
  height: 18px;
  background-image: url(../img/resend.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 575px) {
  a.resend-link,
button.resend-link {
    font-size: 13px;
  }
}

.gradient-text {
  color: transparent;
  background: #ffea00;
  background: linear-gradient(to top, #f99f10, #f99f10 30%, rgb(255, 237, 42) 72%, rgb(255, 237, 42) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.embossing-text {
  background-color: #ffea00;
  color: transparent;
  text-shadow: 0.5px 0.5px 1px #f99f10, 0px 1px 0px rgba(0, 0, 0, 0.18);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}