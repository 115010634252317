html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

body {
  display: block;
  position: relative;
  font-family: $ff-text;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.16;
  background: #1c002f;
  min-width: 320px;
  color: $text-color;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &.lock {
    overflow-y: hidden;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  &.rtl {
    font-family: "Noto Sans Hebrew",'Arial', sans-serif;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:  $accent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}
main {
  flex-grow: 1;
}
input,
textarea {
  box-shadow: none;
}

::placeholder {
  opacity: 0.7;
}

:-ms-input-placeholder {
  opacity: 0.7;
}

::ms-input-placeholder {
  opacity: 0.7;
}
