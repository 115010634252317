main {
  background-image: url(../img/main_bg_empty-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 0;
  overflow: hidden;
  @include md-max {
    padding: 0;
  }
}
.main-title {
  max-width: 450px;
  margin: 0 auto 20px;
  font-family: $ff-title;
  color: #ffea00;
  font-size: 35px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 5px 20px rgba(0, 0, 0, 0.6);
  @include rwd-max(1600) {
    max-width: 400px;
    font-size: 30px;
    line-height: 1.2;
  }
  @include sm-max {
    max-width: 360px;
    font-size: 26px;
  }
}
.main-subtitle {
  max-width: 310px;
  margin: 0 auto 20px;
  font-family: $ff-title;
  color: #f99f10;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
  @include rwd-max(1600) {
    max-width: 270px;
    font-size: 25px;
    line-height: 1.2;
  }
  @include sm-max {
    max-width: 255px;
    font-size: 23px;
  }
}
.main-box {
  padding: 50px 50px 10px;
  background-image: url(../img/bg-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  min-width: 640px;
  width: 33vw;
  min-height: 45vh;
  border: solid 5px #ffdd7f;
  box-shadow: 0px 1px 7.76px 0.24px rgba(0, 0, 0, 0.91);
  position: relative;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include xl-max {
    max-width: 80vw;
  }
  @include md-max {
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    border: none;
    border-radius: 0;
    padding: 65px 20px 100px;
  }
  @include sm-max {
    padding: 55px 20px 100px;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/light2.png);
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 270px;
    width: 100%;
    background-image: linear-gradient(
      to top,
      #240427,
      #240427 80%,
      transparent
    );
    display: none;
    @include md-max {
      display: block;
    }
  }
  &__title {
    font-family: $ff-title;
    color: #f99f10;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    @include md-max {
      font-size: 18px;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  &__left {
    position: absolute;
    top: 43%;
    right: calc(50% + 220px);
    width: 400px;
    transform: translate(0, -50%);
    @include lg-max {
      display: none;
    }
  }
  &__right {
    position: absolute;
    top: 44%;
    left: calc(50% + 180px);
    width: 400px;
    transform: translate(0, -50%);
    @include lg-max {
      display: none;
    }
  }
  &__top {
    position: absolute;
    width: 350px;
    min-width: 300px;
    top: -130px;
    margin: 0 auto;
    z-index: 2;
    @include md-max {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      margin: 0 auto;
    }
    @include sm-max {
      max-width: 270px;
      min-width: auto;
    }
  }
  &__logo {
    max-width: 250px;
    position: absolute;
    top: calc(100% + 30px);
    left: 50%;
    transform: translate(-50%, 0);
    @include rwd-max(1600) {
      max-width: 155px;
      top: calc(100% + 10px);
    }
    @include md-max {
      top: 10px;
    }
    @include sm-max {
      max-width: 125px;
    }
  }
  &__bottom {
    z-index: 10;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__notes {
    max-width: 450px;
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $ff-text;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    @include md-max {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin-top: 0;
    }
    @include sm-max {
      max-width: 200px;
      font-size: 13px;
    }
    a {
      color: #f99f10;
    }
  }
  &__notes-up {
    max-width: 230px;
    margin: 10px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $ff-text;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    @include sm-max {
      max-width: 240px;
      font-size: 13px;
    }
  }
  &__number {
    display: flex;
    flex-direction: column;
    color: #fff;
    max-width: 300px;
    margin: 0 auto;
  }
  &.account {
    padding-bottom: 5px;
  }
}

.account {
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__photo {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: 2px solid #fff;
    transform: rotate(45deg);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      transform: rotate(-45deg);
      object-fit: cover;
    }
  }
  &__details {
    margin-left: 20px;
  }
  &__details,
  &__text {
    font-family: $ff-text;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    text-shadow: 0px 4px 8.4px rgba(0, 0, 0, 1);
  }
  &__name {
    margin-bottom: 2px;
    width: 100px;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__total {
    margin-left: 10px;
    font-family: $ff-title;
    font-size: 30px;
    font-weight: bold;
    color: #ffea00;
    text-shadow: 2px 2px 2px #4c1e02;
  }
  &__powered {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $ff-text;
    font-size: 11px;
    color: #fff;
    img {
      margin-left: 10px;
      max-width: 60px;
    }
  }
  .main-box__title {
    max-width: 340px;
    margin: 0 auto 20px;
    @include md-max {
      max-width: 310px;
    }
  }
}
.get-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 20px;
  &__title {
    display: inline-block;
    margin: 0 auto;
    padding: 10px 30px;
    text-align: center;
    font-size: 20px;
    height: 100%;
    font-family: $ff-title;
    font-weight: bold;
    color: #ffea00;
    text-shadow: 2px 2px 2px #4c1e02;
    text-transform: uppercase;
    position: relative;
    background-image: url(../img/you_get.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__count {
    display: block;
    text-align: center;
    height: 100%;
    font-family: $ff-title;
    font-size: 60px;
    font-weight: bold;
    color: #ffea00;
    text-shadow: 2px 2px 2px #4c1e02;
    &.changed {
      animation-name: change-amount;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }
  &__light {
    max-width: 300px;
    position: absolute;
    bottom: -15px;
    object-fit: cover;
  }
}
@keyframes change-amount {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.animation-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  // lottie-player {
  //   margin-left: 60px;
  // }
  &.show {
    opacity: 1;
  }
  svg {
    margin-left: 30px;
    width: 800px !important;
    height: 800px !important;
  }
}
.get-slider {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  @include sm-max {
    max-width: 60%;
  }
  &__note {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $ff-text;
    color: #fff;
    font-size: 13px;
  }
  .swiper-wrapper {
    max-width: 100vw;
  }
  .swiper-container {
    width: 100%;
    max-width: 500px;
    height: 50px;
    margin: 0 auto;
    overflow: hidden;
    mask-image: linear-gradient(
      to right,
      transparent,
      transparent 10%,
      black 20%,
      black 80%,
      transparent 90%,
      transparent
    );
    // -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 95%);
    // mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 85%, rgba(0,0,0,0));
  }

  .swiper-slide-container {
    text-align: center;
    height: 100%;
    font-family: $ff-title;
    font-size: 25px;
    line-height: 50px;
    font-weight: bold;
    color: #ffea00;
    text-shadow: 2px 2px 2px #4c1e02;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .swiper-slide-active .swiper-slide-container {
    transform: scale(1.1);
    font-size: 40px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    background-position: center;
    background-size: contain;
    width: 40px;
    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    background-image: url(../img/arrow_left-min.png) !important;
    left: 0px;
  }

  .swiper-button-next {
    background-image: url(../img/arrow_right-min.png) !important;
    right: 0px;
  }
}

#phone {
  padding: 8px 13px 8px 60px;
  color: #fff;
  background-color: #250d26;
  border: 2px solid #ffdd7f;
  border-radius: 10px;
  width: 300px;
  color: #ffdd7f;
}
.phone-label {
  margin-bottom: 5px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 0px 1px 6.029px rgba(56, 0, 0, 0.85);
}
.iti__country-list {
  min-width: 300px;
  border: 2px solid #ffdd7f;
  background-color: #250d26;
  border-radius: 0 0 10px 10px;
  z-index: 0;
  margin-top: -7px;
  font-size: 15px;
  font-weight: bold;
  text-shadow: 0px 1px 6.029px rgba(56, 0, 0, 0.85);
  @include md-max {
    border-radius: 10px;
  }
}
.iti__country-list--dropup {
  border-radius: 10px 10px 0 0;
  margin-top: 0;
  margin-bottom: -7px;
  @include md-max {
    border-radius: 10px;
  }
}
.iti__country-name,
.iti__dial-code {
  color: #fff;
  padding-left: 10px;
}
.iti__arrow {
  border-top: 4px solid #ffdd7f;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #ffdd7f;
}
.iti__arrow {
  order: -1;
  margin-right: 10px;
}

#phone-template {
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 35px;
  @include md-max {
    padding-bottom: 0;
  }
}
#varification-template {
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 35px;
}

.timer {
  .timer-on,
  .timer-off {
    display: none;
    &.active {
      display: block;
    }
  }
}

.number-code {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  position: relative;
  & > input:not(:last-child) {
    margin-right: 12px;
  }
  .hint {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    color: #ff0d0d;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    &.show {
      display: block;
    }
  }
}

input.code-input {
  font-size: 30px;
  line-height: 1;
  width: 50px;
  text-align: center;
  flex: 0 0 0;
  padding: 5px;
  border-radius: 15px;
  color: #ffdd7f;
  background: #250d26;
  border: 2px solid #ffdd7f;
  @include sm-max {
    width: 38px;
    font-size: 20px;
    border-radius: 10px;
  }
  &:invalid {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
}
.number-code.error {
  input.code-input {
    color: #ff0d0d;
    border: 2px solid #ff0d0d;
  }
}
.extra {
  width: 90px;
  height: 150px;
  position: absolute;
  right: -40px;
  top: -40px;
  background-image: url(../img/vip_badge-min.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  @include md-max {
    display: none;
    width: 70px;
    height: 100px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  &__text {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $ff-text;
    font-size: 20px;
    font-weight: bold;
    color: #4a0f04;
    text-shadow: 0px 0.5px 0px rgba(74, 15, 4, 0.78);
    @include md-max {
      width: 70px;
      height: 70px;
      font-size: 15px;
    }
  }
  &.mobile {
    display: none;
    @include md-max {
      display: block;
    }
  }
}
.notification-modal {
  min-height: 360px;
  position: relative;
  background: #250d26;
  padding: 40px 25px 30px;
  width: auto;
  max-width: 360px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: solid 5px #ffdd7f;
  border-radius: 20px;
  &__title {
    font-family: $ff-title;
    color: #f99f10;
    font-size: 30px;
    line-height: 1.2;
    font-weight: bold;
    text-align: center;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
  }
  &__text {
    font-family: $ff-title;
    color: #f99f10;
    font-size: 30px;
    line-height: 1.2;
    font-weight: bold;
    text-align: center;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    @include rwd-max(1600) {
      font-size: 25px;
    }
    @include sm-max {
      font-size: 23px;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mfp-close {
    display: none;
  }
}

.mfp-zoom-in {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
}
.balance {
  font-family: $ff-text;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 8.4px rgb(0 0 0);
  @include sm-max {
    max-width: 200px;
    font-size: 13px;
  }
  &__amount {
    font-family: $ff-title;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
    color: #ffea00;
    text-shadow: 2px 2px 2px #4c1e02;
  }
}
a.resend-link,
button.resend-link {
  padding-right: 5px;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $ff-text;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
  &::after {
    content: "";
    position: absolute;
    right: -15px;
    bottom: -5px;
    margin-left: 3px;
    width: 18px;
    height: 18px;
    background-image: url(../img/resend.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: all 0.3s ease;
  }
  @include sm-max {
    font-size: 13px;
  }
}
.gradient-text {
  color: transparent;
  background: #ffea00;
  background: linear-gradient(
    to top,
    #f99f10,
    #f99f10 30%,
    rgba(255, 237, 42, 1) 72%,
    rgba(255, 237, 42, 1) 100%
  );
  // text-shadow: 2px 2px 3px #FF7200, 0px 8px 0px rgba(0, 0, 0, .18);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
.embossing-text {
  background-color: #ffea00;
  color: transparent;
  text-shadow: 0.5px 0.5px 1px #f99f10, 0px 1px 0px rgba(0, 0, 0, 0.18);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
