$ff-text: 'SF Compact Rounded', sans-serif;
$ff-title: 'SF Compact Rounded', sans-serif;

$trs: all 0.3s ease;

// colors
$accent: #E7BB90;
$primary: #9C8F63;
$secondary: #3A2C48;
$text-color: #000;
$gray: #696969;
