@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Regular',
  null, // font weight
  null, // font style
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Bold',
  bold,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Heavy',
  800,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Black',
  900,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Medium',
  500,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Light',
  300,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Ultralight',
  200,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Semibold',
  600,
  null,
  woff2 woff
);
@include font-face(
  'SF Compact Rounded',
  '../fonts/SFCompactRounded/SFCompactRounded-Thin',
  100,
  null,
  woff2 woff
);