.preloader {
  display: none;
}

.js .preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1c002f;
  z-index: 999999;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 30px;
  text-transform: uppercase;
  img {
    padding-left: 25px;
  }
}
