.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  min-width: 200px;
  color: #fff;
  text-shadow: 0px 2px 4.62px rgba(9, 43, 0, 0.64);
  font-family: $ff-title;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  background-image: url(../img/Button_bg-min.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: all 0.3s ease;
  &.isDisabled,
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-image: url(../img/Button_bg-disable.png);
  }
}
.white-link {
  display: inline-block;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $ff-text;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
  @include sm-max {
    font-size: 13px;
  }
}

.posr {
  position: relative;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.df {
  display: flex;
}

.ovh {
  overflow: hidden;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m0 {
  margin: 0 !important;
}

.order-first {
  order: -1;
}

.order-first-sm {
  @include sm {
    order: -1;
  }
}

.order-first-md {
  @include md {
    order: -1;
  }
}

.order-first-lg {
  @include lg {
    order: -1;
  }
}

.order-first-xl {
  @include xl {
    order: -1;
  }
}

.order-first-xxl {
  @include xxl {
    order: -1;
  }
}

$gap1-mobile: 50px;
$gap2-mobile: 40px;
$gap3-mobile: 60px;

$gap1-desktop: 70px;
$gap2-desktop: 80px;
$gap3-desktop: 100px;

.mb-1 {
  margin-bottom: $gap1-mobile;

  @include md {
    margin-bottom: $gap1-desktop;
  }
}

.mb-2 {
  margin-bottom: $gap2-mobile;

  @include md {
    margin-bottom: $gap2-desktop;
  }
}

.mb-3 {
  margin-bottom: $gap3-mobile;

  @include md {
    margin-bottom: $gap3-desktop;
  }
}

.mt-1 {
  margin-top: $gap1-mobile;

  @include md {
    margin-top: $gap1-desktop;
  }
}

.mt-2 {
  margin-top: $gap2-mobile;

  @include md {
    margin-top: $gap2-desktop;
  }
}

.mt-3 {
  margin-top: $gap3-mobile;

  @include md {
    margin-top: $gap3-desktop;
  }
}

.pb-1 {
  padding-bottom: $gap1-mobile;

  @include md {
    padding-bottom: $gap1-desktop;
  }
}

.pb-2 {
  padding-bottom: $gap2-mobile;

  @include md {
    padding-bottom: $gap2-desktop;
  }
}

.pb-3 {
  padding-bottom: $gap3-mobile;

  @include md {
    padding-bottom: $gap3-desktop;
  }
}

.pt-1 {
  padding-top: $gap1-mobile;

  @include md {
    padding-top: $gap1-desktop;
  }
}

.pt-2 {
  padding-top: $gap2-mobile;

  @include md {
    padding-top: $gap2-desktop;
  }
}

.pt-3 {
  padding-top: $gap3-mobile;

  @include md {
    padding-top: $gap3-desktop;
  }
}

.p-1 {
  padding: $gap1-mobile;

  @include md {
    padding: $gap1-desktop;
  }
}

.p-2 {
  padding: $gap2-mobile;

  @include md {
    padding: $gap2-desktop;
  }
}

.p-3 {
  padding: $gap3-mobile;

  @include md {
    padding: $gap3-desktop;
  }
}
