.custom-typography {
 h1, .h1{
  font-family: $ff-title;
 }
 h2, .h2{
  font-family: $ff-title;
 }
 h3, .h3{
  font-family: $ff-title;
 }
 h4, .h4{
  font-family: $ff-title;
 }
 h5, .h5{
  font-family: $ff-title;
 }
 h6, .h6{
  font-family: $ff-title;
 }
 p {
  font-family: $ff-text;
 }
}